import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Customer,
  CustomerListItem,
  CustomerPartnerListItem,
  PartnerCustomerListItem,
  SetCustomerEntraTenantIdRequest,
} from '@models/customers';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class CustomersService {
  private readonly url = `${environment.bffUrl}/customers`;
  constructor(private httpClient: HttpClient) {}

  getAll(includeMarkedForDeletion = false, partnerId?: string): Observable<CustomerListItem[]> {
    return this.httpClient.get<CustomerListItem[]>(this.url, { params: { includeMarkedForDeletion, ...(partnerId ? { partnerId } : {}) } });
  }

  getOne(id: string): Observable<Customer> {
    return this.httpClient.get<Customer>(`${this.url}/${id}`);
  }

  setEntraTenantId(customerId: string, req: SetCustomerEntraTenantIdRequest): Observable<Customer> {
    return this.httpClient.post<Customer>(`${this.url}/${customerId}/set-entra-tenant-id`, req);
  }

  getCustomerPartners(customerId: string): Observable<CustomerPartnerListItem[]> {
    return this.httpClient.get<CustomerPartnerListItem[]>(`${this.url}/customer-partners`, { params: { customerId } });
  }

  getPartnerCustomers(partnerId: string): Observable<PartnerCustomerListItem[]> {
    return this.httpClient.get<PartnerCustomerListItem[]>(`${this.url}/partner-customers`, { params: { partnerId } });
  }
}
