import { TemplateGroup, TemplateGroupAdditionalReceiver } from '@models/notifications';
import { createReducer, on } from '@ngrx/store';
import { findAndReplace, predicateForSorting, sortAlphabetically } from '../../helpers/helper';
import * as fromActions from './template-groups.actions';

export interface State {
  isLoading: boolean;
  templateGroups: TemplateGroup[];

  selectedTemplateGroup: TemplateGroup | null;
  selectedTemplateGroupLoading: boolean;
  templateGroupCreateLoading: boolean;

  additionalReceivers: TemplateGroupAdditionalReceiver[];
  additionalReceiversLoading: boolean;

  selectedAdditionalReceiver: TemplateGroupAdditionalReceiver | null;
  selectedAdditionalReceiverLoading: boolean;
  additionalReceiverCreateLoading: boolean;
}

export const initialState: State = {
  isLoading: false,
  templateGroups: [],

  selectedTemplateGroup: null,
  selectedTemplateGroupLoading: false,
  templateGroupCreateLoading: false,

  additionalReceivers: [],
  additionalReceiversLoading: false,

  selectedAdditionalReceiver: null,
  selectedAdditionalReceiverLoading: false,
  additionalReceiverCreateLoading: false,
};

export const templateGroupsReducer = createReducer(
  initialState,

  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),

  on(fromActions.getAll, state => ({ ...state, isLoading: true })),
  on(fromActions.getAllComplete, (state, { templateGroups }) => ({
    ...state,
    templateGroups: sortAlphabetically(templateGroups, predicateForSorting('name')),
    isLoading: false,
  })),
  on(fromActions.getAllError, state => ({ ...state, isLoading: false })),

  on(fromActions.addTemplateGroup, state => ({ ...state, templateGroupCreateLoading: true, isLoading: true })),
  on(fromActions.addTemplateGroupComplete, (state, { templateGroup }) => ({
    ...state,
    isLoading: false,
    templateGroupCreateLoading: false,
    templateGroups: sortAlphabetically([...state.templateGroups, templateGroup], predicateForSorting('name')),
  })),
  on(fromActions.addTemplateGroupError, state => ({ ...state, isLoading: false, templateGroupCreateLoading: false })),

  on(fromActions.updateTemplateGroup, state => ({ ...state, selectedTemplateGroupLoading: true, isLoading: true })),
  on(fromActions.updateTemplateGroupComplete, (state, { templateGroup }) => ({
    ...state,
    isLoading: false,
    selectedTemplateGroupLoading: false,
    selectedTemplateGroup: templateGroup,
    templateGroups: sortAlphabetically(
      findAndReplace<TemplateGroup>(state.templateGroups, templateGroup, item => item.id === templateGroup.id),
      predicateForSorting('name'),
    ),
  })),
  on(fromActions.updateTemplateGroupError, state => ({
    ...state,
    selectedTemplateGroup: { ...state.selectedTemplateGroup! },
    isLoading: false,
    selectedTemplateGroupLoading: false,
  })),

  on(fromActions.selectTemplateGroup, state => ({ ...state, selectedTemplateGroupLoading: true })),
  on(fromActions.selectTemplateGroupComplete, (state, { templateGroup }) => ({
    ...state,
    selectedTemplateGroupLoading: false,
    selectedTemplateGroup: templateGroup,
  })),
  on(fromActions.selectTemplateGroupError, state => ({ ...state, selectedTemplateGroupLoading: false })),

  on(fromActions.getAdditionalReceivers, state => ({ ...state, additionalReceiversLoading: true })),
  on(fromActions.getAdditionalReceiversComplete, (state, { additionalReceivers }) => ({
    ...state,
    additionalReceiversLoading: false,
    additionalReceivers: additionalReceivers,
  })),
  on(fromActions.getAdditionalReceiversError, state => ({
    ...state,
    additionalReceiversLoading: false,
  })),

  on(fromActions.addAdditionalReceiver, state => ({
    ...state,
    additionalReceiversLoading: true,
    additionalReceiverCreateLoading: true,
  })),
  on(fromActions.addAdditionalReceiverComplete, (state, { additionalReceiver }) => ({
    ...state,
    additionalReceiverCreateLoading: false,
    additionalReceiversLoading: false,
    additionalReceivers: [...state.additionalReceivers, additionalReceiver],
  })),
  on(fromActions.addAdditionalReceiverError, state => ({
    ...state,
    additionalReceiversLoading: false,
    additionalReceiverCreateLoading: false,
  })),
  on(fromActions.deleteSelectedAdditionalReceiver, state => ({ ...state, additionalReceiversLoading: true })),
  on(fromActions.deleteSelectedAdditionalReceiverComplete, state => ({
    ...state,
    additionalReceiversLoading: false,
  })),
  on(fromActions.deleteSelectedAdditionalReceiverError, state => ({
    ...state,
    additionalReceiversLoading: false,
  })),

  on(fromActions.selectAdditionalReceiver, state => ({ ...state, selectedAdditionalReceiverLoading: true })),
  on(fromActions.selectAdditionalReceiverComplete, (state, { additionalReceiver }) => ({
    ...state,
    selectedAdditionalReceiverLoading: false,
    selectedAdditionalReceiver: additionalReceiver,
  })),
  on(fromActions.selectAdditionalReceiverError, state => ({ ...state, selectedAdditionalReceiverLoading: false })),

  on(fromActions.updateAdditionalReceiver, state => ({
    ...state,
    selectedAdditionalReceiverLoading: true,
    additionalReceiversLoading: true,
  })),
  on(fromActions.updateAdditionalReceiverComplete, (state, { additionalReceiver }) => ({
    ...state,
    additionalReceiversLoading: false,
    selectedAdditionalReceiverLoading: false,
    selectedAdditionalReceiver: additionalReceiver,
    additionalReceivers: sortAlphabetically(
      findAndReplace<TemplateGroupAdditionalReceiver>(
        state.additionalReceivers,
        additionalReceiver,
        item => item.id === additionalReceiver.id,
      ),
      predicateForSorting('groupName'),
    ),
  })),
  on(fromActions.updateAdditionalReceiverError, state => ({
    ...state,
    selectedAdditionalReceiver: { ...state.selectedAdditionalReceiver! },
    additionalReceiversLoading: false,
    selectedAdditionalReceiverLoading: false,
  })),
);
