import { NewsArticle, NewsArticleComment, NewsArticleListItem, NewsArticlePromotion, NewsArticleRegion } from '@models/index';
import { createReducer, on } from '@ngrx/store';
import { findAndRemove, findAndReplace, sortAlphabetically } from '../helpers/helper';
import * as fromActions from './news-articles.actions';
import { ToggleOptionsName } from './news-articles.actions';
export interface State {
  toggle: ToggleOptionsName;
  newsArticles: NewsArticleListItem[];
  selectedNewsArticle: NewsArticle | null;
  isLoading: boolean;
  isSelectedNewsArticleLoading: boolean;
  isNewsArticleCreateInProgress: boolean;

  newsArticleComments: NewsArticleComment[];
  newsArticleCommentsLoading: boolean;

  selectedNewsArticleRegionId: string | null;

  newsArticlesByRegion: NewsArticleListItem[];
  newsArticlesByRegionLoading: boolean;

  articlesWithoutSelectedRegion: NewsArticleListItem[];
  articlesWithoutSelectedRegionLoading: boolean;
}

export const initialState: State = {
  newsArticles: [],
  toggle: 'All',
  selectedNewsArticle: null,
  isLoading: false,
  isSelectedNewsArticleLoading: false,
  isNewsArticleCreateInProgress: false,

  newsArticleComments: [],
  newsArticleCommentsLoading: false,

  selectedNewsArticleRegionId: null,

  newsArticlesByRegion: [],
  newsArticlesByRegionLoading: false,

  articlesWithoutSelectedRegion: [],
  articlesWithoutSelectedRegionLoading: false,
};

export const newsArticlesReducer = createReducer(
  initialState,
  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),

  on(fromActions.getAllNewsArticles, (state, { toggle }) => ({ ...state, toggle, isLoading: true })),
  on(fromActions.getAllNewsArticlesComplete, (state, { newsArticles }) => ({
    ...state,
    isLoading: false,
    newsArticles: sortAlphabetically(newsArticles, sortByDateTime),
  })),
  on(fromActions.getAllNewsArticlesError, state => ({ ...state, isLoading: false })),

  on(fromActions.selectNewsArticle, state => ({ ...state, isSelectedNewsArticleLoading: true })),
  on(fromActions.selectNewsArticleComplete, (state, { newsArticle }) => ({
    ...state,
    isSelectedNewsArticleLoading: false,
    selectedNewsArticle: newsArticle,
  })),
  on(fromActions.selectNewsArticleError, state => ({
    ...state,
    isSelectedNewsArticleLoading: false,
  })),

  on(fromActions.getAllNewsArticlesByRegion, state => ({ ...state, newsArticlesByRegionLoading: true })),
  on(fromActions.getAllNewsArticlesByRegionComplete, (state, { newsArticles }) => ({
    ...state,
    newsArticlesByRegionLoading: false,
    newsArticlesByRegion: sortAlphabetically(newsArticles, sortByDateTime),
  })),
  on(fromActions.getAllNewsArticlesByRegionError, state => ({ ...state, newsArticlesByRegionLoading: false })),

  on(fromActions.addNewsArticle, state => ({
    ...state,
    isNewsArticleCreateInProgress: true,
    isSelectedNewsArticleLoading: true,
    newsArticlesByRegionLoading: true,
    isLoading: true,
  })),
  on(fromActions.addNewsArticleComplete, (state, { newsArticle, regionId }) => ({
    ...state,
    selectedNewsArticle: { ...newsArticle, content: null },
    newsArticles: sortAlphabetically([...state.newsArticles, newsArticle], sortByDateTime),
    newsArticlesByRegion: regionId ? sortAlphabetically([...state.newsArticlesByRegion, newsArticle], sortByDateTime) : [],
    newsArticlesByRegionLoading: false,
    isSelectedNewsArticleLoading: false,
    isNewsArticleCreateInProgress: false,
    isLoading: false,
  })),
  on(fromActions.addNewsArticleError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    newsArticlesByRegionLoading: false,
    isNewsArticleCreateInProgress: false,
    isLoading: false,
  })),

  on(fromActions.createNewsArticleThumbnailFile, state => ({ ...state, isSelectedNewsArticleLoading: true, isLoading: true })),
  on(fromActions.updateNewsArticle, state => ({
    ...state,
    isSelectedNewsArticleLoading: true,
    newsArticlesByRegionLoading: true,
    isLoading: true,
  })),
  on(fromActions.updateNewsArticleComplete, (state, { newsArticle }) => ({
    ...state,
    selectedNewsArticle: { content: state.selectedNewsArticle!.content, ...newsArticle },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === newsArticle.id),
      sortByDateTime,
    ),
    newsArticlesByRegion: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticlesByRegion, newsArticle, item => item.id === newsArticle.id),
      sortByDateTime,
    ),
    newsArticlesByRegionLoading: false,
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),
  on(fromActions.updateNewsArticleError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    newsArticlesByRegionLoading: false,
    isLoading: false,
  })),

  on(fromActions.deleteSelectedNewsArticle, state => ({ ...state, isSelectedNewsArticleLoading: true })),
  on(fromActions.deleteSelectedNewsArticleComplete, state => ({
    ...state,
    isSelectedNewsArticleLoading: false,
    selectedNewsArticle: null,
    selectedVersion: null,
  })),
  on(fromActions.deleteSelectedNewsArticleError, state => ({
    ...state,
    isSelectedNewsArticleLoading: false,
  })),

  on(fromActions.activateNewsArticle, state => ({ ...state, isSelectedNewsArticleLoading: true })),
  on(fromActions.activateNewsArticleComplete, (state, { newsArticle }) => ({
    ...state,
    isSelectedNewsArticleLoading: false,
    selectedNewsArticle: { content: state.selectedNewsArticle!.content, ...newsArticle },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === state.selectedNewsArticle!.id),
      sortByDateTime,
    ),
  })),
  on(fromActions.activateNewsArticleError, state => ({ ...state, isSelectedNewsArticleLoading: false })),

  on(fromActions.deactivateNewsArticle, state => ({ ...state, isSelectedNewsArticleLoading: true })),
  on(fromActions.deactivateNewsArticleComplete, (state, { newsArticle }) => ({
    ...state,
    isSelectedNewsArticleLoading: false,
    selectedNewsArticle: { content: state.selectedNewsArticle!.content, ...newsArticle },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === state.selectedNewsArticle!.id),
      sortByDateTime,
    ),
  })),
  on(fromActions.deactivateNewsArticleError, state => ({ ...state, isSelectedNewsArticleLoading: false })),

  on(fromActions.setNewsArticleTopics, state => ({
    ...state,
    isSelectedNewsArticleLoading: true,
    isLoading: true,
  })),
  on(fromActions.setNewsArticleTopicsComplete, (state, { newsArticle }) => ({
    ...state,
    selectedNewsArticle: { content: state.selectedNewsArticle!.content, ...newsArticle },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === newsArticle.id),
      sortByDateTime,
    ),
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),
  on(fromActions.setNewsArticleTopicsError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),

  on(fromActions.setNewsArticleWorkloads, state => ({
    ...state,
    isSelectedNewsArticleLoading: true,
    isLoading: true,
  })),
  on(fromActions.setNewsArticleWorkloadsComplete, (state, { newsArticle }) => ({
    ...state,
    selectedNewsArticle: { content: state.selectedNewsArticle!.content, ...newsArticle },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === newsArticle.id),
      sortByDateTime,
    ),
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),
  on(fromActions.setNewsArticleWorkloadsError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),

  on(fromActions.setNewsArticleMembershipLevels, state => ({ ...state, isSelectedNewsArticleLoading: true, isLoading: true })),
  on(fromActions.setNewsArticleMembershipLevelsComplete, (state, { newsArticle }) => ({
    ...state,
    selectedNewsArticle: { content: state.selectedNewsArticle!.content, ...newsArticle },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === newsArticle.id),
      sortByDateTime,
    ),
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),
  on(fromActions.setNewsArticleMembershipLevelsError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),

  on(fromActions.setNewsArticleContent, state => ({ ...state, isSelectedNewsArticleLoading: true, isLoading: true })),
  on(fromActions.setNewsArticleContentComplete, (state, { newContent }) => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle!, content: newContent.content },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(
        state.newsArticles,
        state.selectedNewsArticle!,
        item => item.id === state.selectedNewsArticle!.id,
      ),
      sortByDateTime,
    ),
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),
  on(fromActions.setNewsArticleContentError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),

  on(fromActions.getAllNewsArticleComments, state => ({ ...state, newsArticleCommentsLoading: true })),
  on(fromActions.getAllNewsArticleCommentsComplete, (state, { newsArticleComments }) => ({
    ...state,
    newsArticleCommentsLoading: false,
    newsArticleComments: sortAlphabetically(newsArticleComments, sortByDateTime),
  })),
  on(fromActions.getAllNewsArticleCommentsError, state => ({ ...state, newsArticleCommentsLoading: false })),

  on(fromActions.deleteNewsArticleComment, state => ({ ...state, newsArticleCommentsLoading: true })),
  on(fromActions.deleteNewsArticleCommentComplete, (state, { commentId }) => ({
    ...state,
    newsArticleComments: findAndRemoveComment(state.newsArticleComments, comment => comment.id === commentId),
    newsArticleCommentsLoading: false,
  })),
  on(fromActions.deleteNewsArticleCommentError, state => ({
    ...state,
    newsArticleCommentsLoading: false,
  })),

  on(fromActions.selectNewsArticleRegion, (state, { regionId }) => ({ ...state, selectedNewsArticleRegionId: regionId })),

  on(fromActions.addNewsArticleRegions, (state, { isRegionView }) => ({
    ...state,
    isSelectedNewsArticleLoading: true,
    isLoading: true,
    articlesWithoutSelectedRegionLoading: isRegionView,
  })),
  on(fromActions.addNewsArticleRegionsComplete, (state, { newsArticle, isRegionView }) => ({
    ...state,
    selectedNewsArticle: state.selectedNewsArticle
      ? { content: state.selectedNewsArticle!.content, ...newsArticle }
      : state.selectedNewsArticle,
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === newsArticle.id),
      sortByDateTime,
    ),
    articlesWithoutSelectedRegion: isRegionView
      ? findAndRemove(state.articlesWithoutSelectedRegion, article => article.id === newsArticle.id)
      : state.articlesWithoutSelectedRegion,
    newsArticlesByRegion: isRegionView ? [...state.newsArticlesByRegion, newsArticle] : state.newsArticlesByRegion,

    isSelectedNewsArticleLoading: false,
    isLoading: false,
    articlesWithoutSelectedRegionLoading: false,
    selectedNewsArticleRegionId: null,
  })),
  on(fromActions.addNewsArticleRegionsError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    articlesWithoutSelectedRegionLoading: false,
    isLoading: false,
  })),

  on(fromActions.updateNewsArticleRegion, state => ({ ...state, isSelectedNewsArticleLoading: true, isLoading: true })),
  on(fromActions.updateNewsArticleRegionComplete, (state, { newsArticle }) => ({
    ...state,
    selectedNewsArticle: { content: state.selectedNewsArticle!.content, ...newsArticle },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === newsArticle.id),
      sortByDateTime,
    ),
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),
  on(fromActions.updateNewsArticleRegionError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),

  on(fromActions.deleteNewsArticleRegion, state => ({
    ...state,
    isSelectedNewsArticleLoading: true,
    newsArticlesByRegionLoading: true,
    isLoading: true,
  })),
  on(fromActions.deleteNewsArticleRegionComplete, (state, { articleId, regionId, isRegionView }) => {
    const newsArticle = {
      ...state.selectedNewsArticle!,
      regions: findAndRemove<NewsArticleRegion>(state.selectedNewsArticle!.regions, item => item.id === regionId),
    };
    return {
      ...state,
      selectedNewsArticle: isRegionView ? null : newsArticle,
      newsArticles: sortAlphabetically(
        findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === articleId),
        sortByDateTime,
      ),
      newsArticlesByRegion: findAndRemove(state.newsArticlesByRegion, item => item.id === articleId),
      isSelectedNewsArticleLoading: false,
      newsArticlesByRegionLoading: false,

      isLoading: false,
    };
  }),
  on(fromActions.deleteNewsArticleRegionError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    newsArticlesByRegionLoading: false,

    isLoading: false,
  })),

  on(fromActions.approveNewsArticleRegion, state => ({
    ...state,
    isSelectedNewsArticleLoading: true,
    newsArticlesByRegionLoading: true,
    isLoading: true,
  })),
  on(fromActions.approveNewsArticleRegionComplete, (state, { newsArticle }) => ({
    ...state,
    selectedNewsArticle: { content: state.selectedNewsArticle!.content, ...newsArticle },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === newsArticle.id),
      sortByDateTime,
    ),
    newsArticlesByRegion: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticlesByRegion, newsArticle, item => item.id === newsArticle.id),
      sortByDateTime,
    ),
    isSelectedNewsArticleLoading: false,
    newsArticlesByRegionLoading: false,

    isLoading: false,
  })),
  on(fromActions.approveNewsArticleRegionError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    newsArticlesByRegionLoading: false,

    isLoading: false,
  })),

  on(fromActions.addNewsArticlePromotion, state => ({ ...state, isSelectedNewsArticleLoading: true, isLoading: true })),
  on(fromActions.addNewsArticlePromotionComplete, (state, { newsArticle }) => ({
    ...state,
    selectedNewsArticle: { content: state.selectedNewsArticle!.content, ...newsArticle },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === newsArticle.id),
      sortByDateTime,
    ),
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),
  on(fromActions.addNewsArticlePromotionError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),

  on(fromActions.updateNewsArticlePromotion, state => ({ ...state, isSelectedNewsArticleLoading: true, isLoading: true })),
  on(fromActions.updateNewsArticlePromotionComplete, (state, { newsArticle }) => ({
    ...state,
    selectedNewsArticle: { content: state.selectedNewsArticle!.content, ...newsArticle },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === newsArticle.id),
      sortByDateTime,
    ),
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),
  on(fromActions.updateNewsArticlePromotionError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),

  on(fromActions.deleteNewsArticlePromotion, state => ({ ...state, isSelectedNewsArticleLoading: true, isLoading: true })),
  on(fromActions.deleteNewsArticlePromotionComplete, (state, { articleId, regionId }) => {
    const newsArticle = {
      ...state.selectedNewsArticle!,
      promotions: findAndRemove<NewsArticlePromotion>(state.selectedNewsArticle!.promotions, item => item.regionId === regionId),
    };
    return {
      ...state,
      selectedNewsArticle: newsArticle,
      newsArticles: sortAlphabetically(
        findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === articleId),
        sortByDateTime,
      ),
      isSelectedNewsArticleLoading: false,
      isLoading: false,
    };
  }),
  on(fromActions.deleteNewsArticlePromotionError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),

  on(fromActions.duplicateNewsArticle, state => ({
    ...state,
    isNewsArticleCreateInProgress: true,
    isSelectedNewsArticleLoading: true,
    newsArticlesByRegionLoading: true,
    isLoading: true,
  })),
  on(fromActions.duplicateNewsArticleComplete, (state, { newsArticle }) => ({
    ...state,
    selectedNewsArticle: newsArticle,
    newsArticles: sortAlphabetically([...state.newsArticles, newsArticle], sortByDateTime),
    newsArticlesByRegion: sortAlphabetically([...state.newsArticlesByRegion, newsArticle], sortByDateTime),
    isSelectedNewsArticleLoading: false,
    isNewsArticleCreateInProgress: false,
    isLoading: false,
    newsArticlesByRegionLoading: false,
  })),
  on(fromActions.duplicateNewsArticleError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    isNewsArticleCreateInProgress: false,
    isLoading: false,
    newsArticlesByRegionLoading: false,
  })),
  on(fromActions.getArticlesWithoutSelectedRegion, state => ({ ...state, articlesWithoutSelectedRegionLoading: true })),
  on(fromActions.getArticlesWithoutSelectedRegionComplete, (state, { articles }) => ({
    ...state,
    articlesWithoutSelectedRegionLoading: false,
    articlesWithoutSelectedRegion: articles,
  })),
  on(fromActions.getArticlesWithoutSelectedRegionError, state => ({ ...state, articlesWithoutSelectedRegionLoading: false })),
  on(fromActions.goToNewsArticleActions, state => ({ ...state, selectedNewsArticleRegionId: null })),
);

export const sortByDateTime = (a: NewsArticleListItem | NewsArticleComment, b: NewsArticleListItem | NewsArticleComment): number =>
  a.createdDateTime < b.createdDateTime ? 1 : -1;

export const findAndRemoveComment = (
  comments: NewsArticleComment[],
  predicate: (comment: NewsArticleComment) => boolean,
): NewsArticleComment[] => {
  return comments.reduce((acc, comment) => {
    if (!predicate(comment)) {
      const newComment = { ...comment };

      if (newComment.comments) {
        newComment.comments = findAndRemoveComment(newComment.comments, predicate);
      }

      acc.push(newComment);
    }
    return acc;
  }, [] as NewsArticleComment[]);
};
