import { createSelector } from '@ngrx/store';
import { getRootState } from '../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.customers);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectCustomers = createSelector(selectModuleState, state => state.customers);

export const selectIsSelectedCustomerLoading = createSelector(selectModuleState, state => state.isSelectedCustomerLoading);
export const selectSelectedCustomer = createSelector(selectModuleState, state => state.selectedCustomer);
export const selectSelectedCustomerId = createSelector(selectModuleState, state => state.selectedCustomer && state.selectedCustomer.id);

export const selectIsCustomerPartnersLoading = createSelector(selectModuleState, state => state.isCustomerPartnersLoading);
export const selectCustomerPartners = createSelector(selectModuleState, state => state.customerPartners);
