import { ActionLog } from '@models/index';
import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[Action Logs]';
export const getActionLogById = createAction(`${NAMESPACE} GetActionLogById`, props<{ actionId: string }>());
export const getActionLogComplete = createAction(`${NAMESPACE} GetActionLogComplete`, props<{ actionLog: ActionLog }>());
export const getActionLogError = createAction(`${NAMESPACE} GetActionLogError`);

export const goToActionLogsList = createAction(`${NAMESPACE} GoToActionLogsList`);
export const goToActionLogsDetails = createAction(`${NAMESPACE} GoToActionLogsDetails`, props<{ actionId: string }>());
