import { Document, DocumentListItem } from '@models/documents';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from './documents.actions';
export interface State {
  documents: DocumentListItem[];
  selectedDocument: Document | null;
  isLoading: boolean;
  isSelectedDocumentLoading: boolean;
  isActionLoading: boolean;
  isFileUploadInProgress: boolean;
}

export const initialState: State = {
  documents: [],
  selectedDocument: null,
  isLoading: false,
  isSelectedDocumentLoading: false,
  isActionLoading: false,
  isFileUploadInProgress: false,
};

export const documentsReducer = createReducer(
  initialState,
  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),
  on(fromActions.getAllDocuments, state => ({ ...state, isLoading: true })),
  on(fromActions.getAllDocumentsComplete, (state, { documents }) => ({ ...state, isLoading: false, documents })),
  on(fromActions.getAllDocumentsError, state => ({ ...state, isLoading: false })),

  on(fromActions.selectDocument, state => ({ ...state, isSelectedDocumentLoading: true, isActionLoading: true })),
  on(fromActions.selectDocumentComplete, (state, { doc }) => ({
    ...state,
    isSelectedDocumentLoading: false,
    isActionLoading: false,
    selectedDocument: doc,
  })),
  on(fromActions.selectDocumentError, state => ({ ...state, isSelectedDocumentLoading: false, isActionLoading: false })),

  on(fromActions.createDocument, state => ({ ...state, isLoading: true, selectedDocument: null })),
  on(fromActions.createDocumentError, state => ({ ...state, isLoading: false })),

  on(fromActions.uploadFileToDocument, state => ({
    ...state,
    isLoading: true,
    isSelectedDocumentLoading: false,
    isActionLoading: false,
    isFileUploadInProgress: true,
  })),
  on(fromActions.uploadFileToDocumentComplete, (state, { document }) => ({
    ...state,
    isLoading: false,
    selectedDocument: document ? document : state.selectedDocument,
    isActionLoading: false,
    isFileUploadInProgress: false,
  })),
  on(fromActions.uploadFileToDocumentError, state => ({
    ...state,
    isLoading: false,
    isActionLoading: false,
    isFileUploadInProgress: false,
  })),

  on(fromActions.getFileUploadURL, state => ({
    ...state,
    isLoading: true,
    isFileUploadInProgress: true,
  })),
  on(fromActions.getFileUploadURLError, state => ({
    ...state,
    isLoading: false,
    isFileUploadInProgress: false,
  })),

  on(fromActions.updateDocumentCommonData, state => ({ ...state, isLoading: true, isActionLoading: true })),
  on(fromActions.updateDocumentCommonDataComplete, (state, { doc }) => ({
    ...state,
    isLoading: false,
    selectedDocument: doc,
    isActionLoading: false,
  })),
  on(fromActions.updateDocumentCommonDataError, state => ({ ...state, isLoading: false, isActionLoading: false })),

  on(fromActions.updateDocument, state => ({ ...state, isLoading: true, isActionLoading: true })),
  on(fromActions.updateDocumentComplete, (state, { doc }) => ({
    ...state,
    isLoading: false,
    selectedDocument: doc,
    isActionLoading: false,
  })),
  on(fromActions.updateDocumentError, state => ({
    ...state,
    isLoading: false,
    selectedDocument: { ...state.selectedDocument! },
    isActionLoading: false,
  })),

  on(fromActions.publishOrUnpublishDocument, state => ({
    ...state,
    isLoading: true,
    isActionLoading: true,
    isSelectedDocumentLoading: true,
  })),
  on(fromActions.publishOrUnpublishDocumentComplete, (state, { doc }) => ({
    ...state,
    isLoading: false,
    selectedDocument: doc,
    isSelectedDocumentLoading: false,
    isActionLoading: false,
  })),
  on(fromActions.publishOrUnpublishDocumentError, state => ({
    ...state,
    isLoading: false,
    isActionLoading: false,
    isSelectedDocumentLoading: false,
  })),

  on(fromActions.deleteSelectedDocument, state => ({ ...state, isActionLoading: true, isLoading: true, isSelectedDocumentLoading: true })),
  on(fromActions.deleteSelectedDocumentComplete, state => ({
    ...state,
    selectedDocument: null,
    isActionLoading: false,
    isLoading: false,
    isSelectedDocumentLoading: false,
  })),
  on(fromActions.deleteSelectedDocumentError, state => ({ ...state, isActionLoading: false })),

  on(fromActions.downloadSelectedDocument, state => ({ ...state, isLoading: true, isSelectedDocumentLoading: true })),
  on(fromActions.downloadSelectedDocumentComplete, state => ({
    ...state,
    isLoading: false,
    isSelectedDocumentLoading: false,
  })),
  on(fromActions.downloadSelectedDocumentError, state => ({ ...state, isLoading: false, isSelectedDocumentLoading: false })),
);
