import { ActionLog } from '@models/actions/action-log.model';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from './action-logs.actions';

export interface State {
  selectedActionLog: ActionLog | null;
  isLoadingList: boolean;
  selectedActionLogLoading: boolean;
}

export const initialState: State = {
  selectedActionLog: null,
  isLoadingList: false,
  selectedActionLogLoading: false,
};

export const actionLogsReducer = createReducer(
  initialState,
  on(fromActions.getActionLogById, state => ({
    ...state,
    selectedActionLogLoading: true,
  })),
  on(fromActions.getActionLogComplete, (state, { actionLog }) => ({
    ...state,
    selectedActionLog: actionLog,
    selectedActionLogLoading: false,
  })),
  on(fromActions.getActionLogError, state => ({
    ...state,
    selectedActionLog: null,
    selectedActionLogLoading: false,
  })),
);
