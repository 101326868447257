import { createSelector } from '@ngrx/store';
import { getRootState } from '../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.actionLogs);

export const selectIsLoadingList = createSelector(selectModuleState, state => state.isLoadingList);
export const selectCurrentActionLogId = createSelector(selectModuleState, state => state.selectedActionLog?.id ?? null);
export const selectCurrentActionLog = createSelector(selectModuleState, state => state.selectedActionLog);
export const selectCurrentActionLogLoading = createSelector(selectModuleState, state => state.selectedActionLogLoading);

const convertCamelCaseToTitleCaseText = (text: string): string => {
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};
