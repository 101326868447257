import { GroupedRoles } from '@models/authorization';
import { UserListItemResponse } from '@models/user';
import { createReducer, on } from '@ngrx/store';
import { findAndReplace } from '../helpers/helper';
import * as fromActions from './users.actions';

export interface State {
  users: UserListItemResponse[];
  selectedUser: UserListItemResponse | null;
  isLoadingUser: boolean;

  selectedUserRoles: GroupedRoles[];
  isUserRolesLoading: boolean;

  changingAdditionalPartnerListInProgress: boolean;
  isLoadingUserList: boolean;
}

export const initialState: State = {
  users: [],
  selectedUser: null,

  selectedUserRoles: [],
  isUserRolesLoading: false,

  isLoadingUser: false,
  changingAdditionalPartnerListInProgress: false,
  isLoadingUserList: false,
};

export const userReducer = createReducer(
  initialState,

  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),
  on(fromActions.query, state => ({ ...state, isLoadingUserList: true })),
  on(fromActions.queryComplete, (state, { users }) => ({ ...state, users, isLoadingUserList: false })),
  on(fromActions.queryError, state => ({ ...state, isLoadingUserList: false })),

  on(fromActions.getUserById, state => ({ ...state, selectedUserRoles: [], isLoadingUser: true })),
  on(fromActions.getUserByIdComplete, (state, { user }) => ({ ...state, selectedUser: user, isLoadingUser: false })),
  on(fromActions.getUserByIdError, state => ({ ...state, selectedUser: null, isLoadingUser: false })),

  on(fromActions.getUserRoles, state => ({ ...state, isUserRolesLoading: true })),
  on(fromActions.getUserRolesComplete, (state, { roles }) => ({ ...state, selectedUserRoles: roles, isUserRolesLoading: false })),
  on(fromActions.getUserRolesError, state => ({ ...state, isUserRolesLoading: false })),

  on(fromActions.updateUserRoles, state => ({ ...state, isUserRolesLoading: true })),
  on(fromActions.updateUserRolesComplete, (state, { roles }) => ({
    ...state,
    selectedUserRoles: roles,
    isUserRolesLoading: false,
  })),
  on(fromActions.updateUserRolesError, state => ({ ...state, isUserRolesLoading: false })),

  on(fromActions.blockUnblockUser, state => ({
    ...state,
    isLoadingUserList: true,
    isLoadingUser: true,
  })),
  on(fromActions.blockUnblockUserComplete, (state, { user }) => ({
    ...state,
    selectedUser: user,
    users: findAndReplace(state.users, user, u => u.id === user.id),
    isLoadingUserList: false,
    isLoadingUser: false,
  })),
  on(fromActions.blockUnblockUserError, state => ({
    ...state,
    isLoadingUserList: false,
    isLoadingUser: false,
  })),

  on(fromActions.markForDeletion, state => ({
    ...state,
    isLoadingUserList: true,
    isLoadingUser: true,
  })),
  on(fromActions.markForDeletionComplete, (state, { user }) => ({
    ...state,
    selectedUser: user,
    users: findAndReplace(state.users, user, u => u.id === user.id),
    isLoadingUserList: false,
    isLoadingUser: false,
  })),
  on(fromActions.markForDeletionError, state => ({
    ...state,
    isLoadingUserList: false,
    isLoadingUser: false,
  })),

  on(fromActions.updateCrmAccId, state => ({
    ...state,
    isLoadingUserList: true,
    isLoadingUser: true,
  })),
  on(fromActions.updateCrmAccIdComplete, (state, { user }) => ({
    ...state,
    selectedUser: user,
    isLoadingUserList: false,
    isLoadingUser: false,
  })),
  on(fromActions.updateCrmAccIdError, state => ({
    ...state,
    isLoadingUserList: false,
    isLoadingUser: false,
  })),

  on(fromActions.addAdditionalPartnerForUser, state => ({
    ...state,
    isLoadingUser: true,
    changingAdditionalPartnerListInProgress: true,
  })),
  on(fromActions.addAdditionalPartnerForUserComplete, (state, { user }) => ({
    ...state,
    isLoadingUser: false,
    selectedUser: user,
    changingAdditionalPartnerListInProgress: false,
  })),
  on(fromActions.addAdditionalPartnerForUserError, state => ({
    ...state,
    isLoadingUser: false,
    changingAdditionalPartnerListInProgress: false,
  })),

  on(fromActions.removeAdditionalPartnerFromUser, (state, { userId }) => ({
    ...state,
    isLoadingUser: true,
    changingAdditionalPartnerListInProgress: true,
  })),
  on(fromActions.removeAdditionalPartnerFromUserComplete, (state, { user }) => ({
    ...state,
    isLoadingUser: false,
    selectedUser: user,
    changingAdditionalPartnerListInProgress: false,
  })),
  on(fromActions.removeAdditionalPartnerFromUserError, state => ({
    ...state,
    isLoadingUser: false,
    changingAdditionalPartnerListInProgress: false,
  })),

  on(fromActions.resetUserTwoFaById, state => ({ ...state, isLoadingUserList: true, isLoadingUser: true })),
  on(fromActions.resetUserTwoFaComplete, state => ({ ...state, isLoadingUserList: false, isLoadingUser: false })),
  on(fromActions.resetUserTwoFaError, state => ({ ...state, isLoadingUserList: false, isLoadingUser: false })),

  on(fromActions.downloadPermissions, state => ({ ...state, isLoadingUserList: true, isLoadingUser: true })),
  on(fromActions.downloadPermissionsComplete, state => ({ ...state, isLoadingUserList: false, isLoadingUser: false })),
  on(fromActions.downloadPermissionsError, state => ({ ...state, isLoadingUserList: false, isLoadingUser: false })),

  on(fromActions.clearUserList, state => ({ ...state, users: [] })),
);
