import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mapDefinedParams } from '@highlander/services/helpers';
import { ActionLog, ActionLogListItem, ActionLogsListQuery } from '@models/index';
import { map, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ActionLogsService {
  constructor(private httpClient: HttpClient) {}

  prevActionLogQuery: ActionLogsListQuery | null = null;
  private _continuationToken: string = '';

  getActionLogs(query: ActionLogsListQuery): Observable<ActionLogListItem[]> {
    const params = mapDefinedParams(query);

    return this.httpClient
      .get<ActionLogListItem[]>(`/${environment.bffUrl}/action-logs`, {
        params,
        headers: {
          'x-highlander-continuation-token': this.getContinuationToken(JSON.stringify(this.prevActionLogQuery) !== JSON.stringify(query)),
        },
        observe: 'response',
      })
      .pipe(
        tap(res => {
          this.prevActionLogQuery = query;
          this._continuationToken = res.headers.get('x-highlander-continuation-token') ?? '';
        }),
        map(res => res.body!),
      );
  }

  getActionLog(id: string): Observable<ActionLog> {
    return this.httpClient.get<ActionLog>(`/${environment.bffUrl}/action-logs/${id}`);
  }

  private getContinuationToken(didQueryParamsChange: boolean): string {
    if (didQueryParamsChange) return '';

    return this._continuationToken;
  }
}
