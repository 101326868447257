import { createSelector } from '@ngrx/store';
import { getRootState } from '../../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.notificationExternalTemplates);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectIsTemplateCreateLoading = createSelector(selectModuleState, state => state.templateCreateLoading);
export const selectTemplates = createSelector(selectModuleState, state => state.templates);

export const selectSelectedTemplate = createSelector(selectModuleState, state => state.selectedTemplate);
export const selectSelectedTemplateId = createSelector(selectModuleState, state => state.selectedTemplate && state.selectedTemplate.id);
export const selectSelectedTemplateLoading = createSelector(selectModuleState, state => state.selectedTemplateLoading);

export const selectTags = createSelector(selectModuleState, state => state.tags);
export const selectTagsLoading = createSelector(selectModuleState, state => state.tagsLoading);

export const selectSelectedTag = createSelector(selectModuleState, state => state.selectedTag);
export const selectSelectedTagId = createSelector(selectModuleState, state => state.selectedTag && state.selectedTag.id);
export const selectIsTagCreateLoading = createSelector(selectModuleState, state => state.tagCreateLoading);
export const selectSelectedTagLoading = createSelector(selectModuleState, state => state.selectedTagLoading);
