import {
  ExternalTag,
  ExternalTagResponse,
  ExternalTemplate,
  ExternalTemplateResponse,
} from '@models/notifications/external-templates/external-templates.model';
import { createReducer, on } from '@ngrx/store';
import { findAndReplace, sortAlphabetically } from '../../helpers/helper';
import * as fromActions from './external-templates.actions';

export interface State {
  isLoading: boolean;
  templateCreateLoading: boolean;
  templates: ExternalTemplateResponse[];

  selectedTemplate: ExternalTemplateResponse | null;
  selectedTemplateLoading: boolean;

  tagCreateLoading: boolean;
  tags: ExternalTagResponse[];
  tagsLoading: boolean;

  selectedTag: ExternalTagResponse | null;
  selectedTagLoading: boolean;
}

export const initialState: State = {
  isLoading: false,
  templateCreateLoading: false,
  templates: [],

  selectedTemplate: null,
  selectedTemplateLoading: false,

  tagCreateLoading: false,
  tags: [],
  tagsLoading: false,

  selectedTag: null,
  selectedTagLoading: false,
};

export const externalTemplatesReducer = createReducer(
  initialState,

  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),

  on(fromActions.getAllTemplates, state => ({ ...state, isLoading: true })),
  on(fromActions.getAllTemplatesComplete, (state, { templates }) => ({
    ...state,
    isLoading: false,
    templates: sortAlphabetically<ExternalTemplate>([...templates], (a, b) => a.name.localeCompare(b.name)),
  })),
  on(fromActions.getAllTemplatesError, state => ({ ...state, isLoading: false })),

  on(fromActions.addTemplate, state => ({ ...state, templateCreateLoading: true, isLoading: true })),
  on(fromActions.addTemplateComplete, (state, { template }) => ({
    ...state,
    isLoading: false,
    templateCreateLoading: false,
    templates: sortAlphabetically<ExternalTemplate>([...state.templates, template], (a, b) => a.name.localeCompare(b.name)),
  })),
  on(fromActions.addTemplateError, state => ({ ...state, isLoading: false, templateCreateLoading: false })),

  on(fromActions.updateTemplate, state => ({ ...state, selectedTemplateLoading: true, isLoading: true })),
  on(fromActions.updateTemplateComplete, (state, { template }) => ({
    ...state,
    isLoading: false,
    selectedTemplateLoading: false,
    selectedTemplate: template,
    templates: sortAlphabetically(
      findAndReplace<ExternalTemplate>(state.templates, template, item => item.id === template.id),
      (a, b) => a.name.localeCompare(b.name),
    ),
  })),
  on(fromActions.updateTemplateError, state => ({
    ...state,
    selectedTemplate: { ...state.selectedTemplate! },
    isLoading: false,
    selectedTemplateLoading: false,
  })),

  on(fromActions.selectTemplate, state => ({ ...state, selectedTemplateLoading: true })),
  on(fromActions.selectTemplateComplete, (state, { template }) => ({
    ...state,
    selectedTemplateLoading: false,
    selectedTemplate: template,
  })),
  on(fromActions.selectTemplateError, state => ({ ...state, selectedTemplateLoading: false })),

  on(fromActions.deleteSelectedTemplate, state => ({ ...state, selectedTemplateLoading: true })),
  on(fromActions.deleteSelectedTemplateComplete, state => ({
    ...state,
    selectedTemplateLoading: false,
    selectedTemplate: null,
    selectedTag: null,
    tags: [],
  })),
  on(fromActions.deleteSelectedTemplateError, state => ({
    ...state,
    selectedTemplateLoading: false,
  })),

  on(fromActions.getAllTags, state => ({ ...state, tags: [], tagsLoading: true })),
  on(fromActions.getAllTagsComplete, (state, { tags }) => ({
    ...state,
    tagsLoading: false,
    tags: sortAlphabetically<ExternalTag>([...tags], (a, b) => a.name.localeCompare(b.name)),
  })),
  on(fromActions.getAllTagsError, state => ({ ...state, tagsLoading: false })),

  on(fromActions.addTag, state => ({ ...state, tagsLoading: true, tagCreateLoading: true })),
  on(fromActions.addTagComplete, (state, { tag }) => ({
    ...state,
    tagCreateLoading: false,
    tagsLoading: false,
    tags: sortAlphabetically<ExternalTag>([...state.tags, tag], (a, b) => a.name.localeCompare(b.name)),
  })),
  on(fromActions.addTagError, state => ({ ...state, tagCreateLoading: false, tagsLoading: false })),

  on(fromActions.updateTag, state => ({ ...state, selectedTagLoading: true, tagsLoading: true })),
  on(fromActions.updateTagComplete, (state, { tag }) => ({
    ...state,
    tagsLoading: false,
    selectedTagLoading: false,
    selectedTag: tag,
    tags: sortAlphabetically(
      findAndReplace<ExternalTag>(state.tags, tag, item => item.id === tag.id),
      (a, b) => a.name.localeCompare(b.name),
    ),
  })),
  on(fromActions.updateTagError, state => ({
    ...state,
    selectedTag: { ...state.selectedTag! },
    tagsLoading: false,
    selectedTagLoading: false,
  })),

  on(fromActions.selectTag, state => ({ ...state, selectedTagLoading: true })),
  on(fromActions.selectTagComplete, (state, { tag }) => ({
    ...state,
    selectedTagLoading: false,
    selectedTag: tag,
  })),
  on(fromActions.selectTagError, state => ({ ...state, selectedTagLoading: false })),

  on(fromActions.deleteSelectedTag, state => ({ ...state, selectedTagLoading: true })),
  on(fromActions.deleteSelectedTagComplete, state => ({
    ...state,
    selectedtagLoading: false,
    selectedTag: null,
  })),
  on(fromActions.deleteSelectedTagError, state => ({
    ...state,
    selectedTagLoading: false,
  })),
);
