import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { cspTenantsSelectors } from '@appState';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { DialogService, isNotNullOrUndefined } from 'cui-components';
import { catchError, filter, map, of, switchMap, take, tap } from 'rxjs';
import { requiresCspTenantReauthentication } from 'src/app/app-pages/csp/util';
import { ConfirmationDialogComponent, ConfirmationDialogData } from 'src/app/components/dialogs';
import { AppState } from '../../app.store';
import * as fromActions from './tenants.actions';
import { CspTenantsService } from './tenants.service';
@Injectable()
export class CspTenantsEffects {
  onGetTenants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAll),
      switchMap(() =>
        this.tenantsService.getAll().pipe(
          map(tenants => fromActions.getAllComplete({ tenants })),
          catchError(err => of(fromActions.getAllError({ err }))),
        ),
      ),
    ),
  );

  onAddTenant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addTenant),
      switchMap(({ tenant }) =>
        this.tenantsService.add(tenant).pipe(
          map(tenant => fromActions.addTenantComplete({ tenant })),
          catchError(err => of(fromActions.addTenantError({ err }))),
        ),
      ),
    ),
  );

  onAddTenantComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addTenantComplete),
        switchMap(response => {
          return this.router.navigate(['csp', 'tenants', response.tenant.id, 'details']);
        }),
      ),
    { dispatch: false },
  );

  onUpdateTenant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateTenant),
      switchMap(({ id, tenant }) =>
        this.tenantsService.update(id, tenant).pipe(
          map(tenant => fromActions.updateTenantComplete({ tenant })),
          catchError(err => of(fromActions.updateTenantError({ err }))),
        ),
      ),
    ),
  );

  onUpdateTenantComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateTenantComplete),
      map(() => fromActions.getAll()),
    ),
  );

  onRenewTenantSecret$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.renewTenantSecret),
      switchMap(({ tenant }) =>
        this.tenantsService.renewSecret(tenant.id).pipe(
          map(tenant => fromActions.renewTenantSecretComplete({ tenant })),
          catchError(err => of(fromActions.renewTenantSecretError({ err, tenant }))),
        ),
      ),
    ),
  );

  onRenewTenantSecretError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.renewTenantSecretError),
        tap(({ err, tenant }) => {
          if (requiresCspTenantReauthentication(err)) {
            this.dialogService.openDialog<ConfirmationDialogData, undefined>(ConfirmationDialogComponent, {
              title: 'Renew Secret',
              description: 'Please reauthenticate to renew secret. You will be redirected to reauthentication in 5 seconds.',
              cancelText: 'OK',
              confirmText: '',
              showCloseBtn: false,
            }),
              setTimeout(() => {
                location.href = tenant.authLink;
              }, 5000);
          }
        }),
      ),
    { dispatch: false },
  );

  onSelectTenant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectTenant),
      switchMap(({ id }) =>
        this.tenantsService.get(id).pipe(
          map(tenant => fromActions.selectTenantComplete({ tenant })),
          catchError(err => of(fromActions.selectTenantError({ err }))),
        ),
      ),
    ),
  );

  onDeleteTenant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedTenant),
      switchMap(() =>
        this.store.pipe(
          select(cspTenantsSelectors.selectSelectedTenant),
          filter(isNotNullOrUndefined),
          take(1),
          switchMap(tenant =>
            this.tenantsService.delete(tenant.id).pipe(
              map(() => fromActions.deleteSelectedTenantComplete()),
              catchError(err => of(fromActions.deleteSelectedTenantError({ err }))),
            ),
          ),
        ),
      ),
    ),
  );

  onDeleteTenantComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedTenantComplete),
      tap(() => this.router.navigate(['csp', 'tenants'])),
      map(() => fromActions.getAll()),
    ),
  );

  onGoToTenantActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToTenantActions),
        tap(({ id }) => this.router.navigate(['csp', 'tenants', id])),
      ),
    { dispatch: false },
  );

  onGoToListPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToListPage),
      tap(() => this.router.navigate(['csp', 'tenants'])),
      map(() => fromActions.resetState({ selectedTenant: null })),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
    private tenantsService: CspTenantsService,
    private dialogService: DialogService,
  ) {}
}
