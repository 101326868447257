import { Clipboard } from '@angular/cdk/clipboard';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { regionsSelectors } from '@appState';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { BlobStorageService, isNotNullOrUndefined, NotificationsService } from 'cui-components';
import { catchError, filter, map, of, switchMap, take, tap } from 'rxjs';
import { AppConfigService } from 'src/app/infrastructure';
import { AppState } from '../app.store';
import * as fromActions from './news-articles.actions';
import * as newsArticlesSelectors from './news-articles.selector';
import { NewsArticlesService } from './news-articles.service';
@Injectable()
export class NewsArticlesEffects {
  onGetNewsArticles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAllNewsArticles),
      switchMap(({ toggle }) =>
        this.newsArticlesService.getAll(toggle === 'Active' ? { status: toggle } : undefined).pipe(
          map(newsArticles => fromActions.getAllNewsArticlesComplete({ newsArticles })),
          catchError(err => of(fromActions.getAllNewsArticlesError({ err }))),
        ),
      ),
    ),
  );

  onSelectNewsArticle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectNewsArticle),
      switchMap(({ id, includeContent }) =>
        this.newsArticlesService.getOne(id, includeContent).pipe(
          map(newsArticle => fromActions.selectNewsArticleComplete({ newsArticle })),
          catchError(err => of(fromActions.selectNewsArticleError({ err }))),
        ),
      ),
    ),
  );

  onGetNewsByRegionArticles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAllNewsArticlesByRegion),
      switchMap(({ regionId }) =>
        this.newsArticlesService.getAll({ regionId }).pipe(
          map(newsArticles => fromActions.getAllNewsArticlesByRegionComplete({ newsArticles })),
          catchError(err => of(fromActions.getAllNewsArticlesByRegionError({ err }))),
        ),
      ),
    ),
  );

  onAddNewsArticle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addNewsArticle),
      switchMap(({ newsArticle, regionId }) =>
        this.newsArticlesService.create(newsArticle).pipe(
          map(newsArticle => fromActions.addNewsArticleComplete({ newsArticle, regionId })),
          catchError(err => of(fromActions.addNewsArticleError({ err }))),
        ),
      ),
    ),
  );

  onUpdateNewsArticle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateNewsArticle),
      switchMap(({ newsArticle, id }) =>
        this.newsArticlesService.update(id, newsArticle).pipe(
          map(newsArticle => fromActions.updateNewsArticleComplete({ newsArticle })),
          catchError(err => of(fromActions.updateNewsArticleError({ err }))),
        ),
      ),
    ),
  );

  onDeleteNewsArticle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedNewsArticle),
      switchMap(() =>
        this.store.pipe(
          select(newsArticlesSelectors.selectSelectedNewsArticleId),
          take(1),
          switchMap(id =>
            this.newsArticlesService.delete(id!).pipe(
              map(() => fromActions.deleteSelectedNewsArticleComplete()),
              catchError(err => of(fromActions.deleteSelectedNewsArticleError({ err }))),
            ),
          ),
        ),
      ),
    ),
  );

  onSelectNewsArticleError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectNewsArticleError),
      map(() => fromActions.goToNewsArticleListPage()),
    ),
  );

  onDeleteNewsArticleComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedNewsArticleComplete),
      switchMap(() =>
        this.store.pipe(
          select(newsArticlesSelectors.selectToggle),
          take(1),
          tap(() => this.router.navigate(['marketing', 'news-articles'])),
          switchMap(toggle => of(fromActions.getAllNewsArticles({ toggle }))),
        ),
      ),
    ),
  );

  onAddNewsArticleComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addNewsArticleComplete),
        tap(({ newsArticle, regionId }) =>
          regionId
            ? this.router.navigate(['marketing', 'regions', regionId, 'news-articles', newsArticle.id, 'details'])
            : this.router.navigate(['marketing', 'news-articles', newsArticle.id, 'details']),
        ),
      ),
    { dispatch: false },
  );

  onGoToNewsArticleActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToNewsArticleActions),
        tap(({ id }) => this.router.navigate(['marketing', 'news-articles', id])),
      ),
    { dispatch: false },
  );

  onGoToListPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToNewsArticleListPage),
      tap(() => this.router.navigate(['marketing', 'news-articles'])),
      map(() => fromActions.resetState({ selectedNewsArticle: null })),
    ),
  );

  onActivateNewsArticle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.activateNewsArticle),
      switchMap(({ id }) =>
        this.newsArticlesService.activate(id).pipe(
          map(newsArticle => fromActions.activateNewsArticleComplete({ newsArticle })),
          catchError(err => of(fromActions.activateNewsArticleError({ err }))),
        ),
      ),
    ),
  );

  onDeactivateNewsArticle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deactivateNewsArticle),
      switchMap(({ id }) =>
        this.newsArticlesService.deactivate(id).pipe(
          map(newsArticle => fromActions.deactivateNewsArticleComplete({ newsArticle })),
          catchError(err => of(fromActions.deactivateNewsArticleError({ err }))),
        ),
      ),
    ),
  );

  onSetNewsArticleWorkloads = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setNewsArticleWorkloads),
      switchMap(({ id, workloads }) =>
        this.newsArticlesService.setWorkloads(id, workloads).pipe(
          map(newsArticle => fromActions.setNewsArticleWorkloadsComplete({ newsArticle })),
          catchError(err => of(fromActions.setNewsArticleWorkloadsError({ err }))),
        ),
      ),
    ),
  );

  onSetNewsArticleTopics = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setNewsArticleTopics),
      switchMap(({ id, topics }) =>
        this.newsArticlesService.setTopics(id, topics).pipe(
          map(newsArticle => fromActions.setNewsArticleTopicsComplete({ newsArticle })),
          catchError(err => of(fromActions.setNewsArticleTopicsError({ err }))),
        ),
      ),
    ),
  );

  onSetNewsArticleMembershipLevels = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setNewsArticleMembershipLevels),
      switchMap(({ id, membershipLevels }) =>
        this.newsArticlesService.setMembershipLevels(id, membershipLevels).pipe(
          map(newsArticle => fromActions.setNewsArticleMembershipLevelsComplete({ newsArticle })),
          catchError(err => of(fromActions.setNewsArticleMembershipLevelsError({ err }))),
        ),
      ),
    ),
  );

  onSetNewsArticleContent = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setNewsArticleContent),
      switchMap(({ id, content }) =>
        this.newsArticlesService.setContent(id, content).pipe(
          map(newContent => fromActions.setNewsArticleContentComplete({ newContent })),
          catchError(err => of(fromActions.setNewsArticleContentError({ err }))),
        ),
      ),
    ),
  );

  onCreateNewsArticleThumbnailFile = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createNewsArticleThumbnailFile),
      switchMap(({ id, file, updateReq }) =>
        this.newsArticlesService.createThumbnailFile(id).pipe(
          map(response => fromActions.uploadNewsArticleThumbnailFile({ response, file, id, updateReq })),
          catchError(err => of(fromActions.updateNewsArticleError({ err }))),
        ),
      ),
    ),
  );

  onUploadNewsArticleThumbnail = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.uploadNewsArticleThumbnailFile),
      switchMap(({ response, file, id, updateReq }) =>
        this.blobStorageService.uploadFile(response.uploadURL, file).pipe(
          map(() => fromActions.updateNewsArticle({ id, newsArticle: { ...updateReq, pictureId: response.id } })),
          catchError(err => of(fromActions.updateNewsArticleError({ err }))),
        ),
      ),
    ),
  );

  onGetNewsArticleComments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAllNewsArticleComments),
      switchMap(({ articleId, includeSubComments, regionId }) =>
        this.newsArticlesService.getAllNewsArticleComments(articleId, includeSubComments, regionId).pipe(
          map(newsArticleComments => {
            return fromActions.getAllNewsArticleCommentsComplete({ newsArticleComments });
          }),
          catchError(err => of(fromActions.getAllNewsArticleCommentsError({ err }))),
        ),
      ),
    ),
  );

  onDeleteNewsArticleComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteNewsArticleComment),
      switchMap(({ articleId, commentId }) =>
        this.newsArticlesService.deleteComment(articleId, commentId).pipe(
          map(() => fromActions.deleteNewsArticleCommentComplete({ commentId })),
          catchError(err => of(fromActions.deleteNewsArticleCommentError({ err }))),
        ),
      ),
    ),
  );

  onAddNewsArticleRegions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addNewsArticleRegions),
      switchMap(({ articleId, regions, isRegionView }) =>
        this.newsArticlesService.addRegions(articleId, regions).pipe(
          map(newsArticle => fromActions.addNewsArticleRegionsComplete({ newsArticle, isRegionView })),
          catchError(err => of(fromActions.addNewsArticleRegionsError({ err }))),
        ),
      ),
    ),
  );

  onApproveNewsArticleRegion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.approveNewsArticleRegion),
      switchMap(({ articleId, regionId }) =>
        this.newsArticlesService.approveRegion(articleId, regionId).pipe(
          map(newsArticle => fromActions.approveNewsArticleRegionComplete({ newsArticle })),
          catchError(err => of(fromActions.approveNewsArticleRegionError({ err }))),
        ),
      ),
    ),
  );

  onUpdateNewsArticleRegion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateNewsArticleRegion),
      switchMap(({ articleId, regionId, region }) =>
        this.newsArticlesService.updateRegion(articleId, regionId, region).pipe(
          map(newsArticle => fromActions.updateNewsArticleRegionComplete({ newsArticle })),
          catchError(err => of(fromActions.updateNewsArticleRegionError({ err }))),
        ),
      ),
    ),
  );

  onDeleteNewsArticleRegion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteNewsArticleRegion),
      switchMap(({ articleId, regionId, isRegionView }) =>
        this.newsArticlesService.deleteRegion(articleId, regionId).pipe(
          map(() => fromActions.deleteNewsArticleRegionComplete({ articleId, regionId, isRegionView })),
          catchError(err => of(fromActions.deleteNewsArticleRegionError({ err }))),
        ),
      ),
    ),
  );

  onDeleteNewsArticleRegionComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.deleteNewsArticleRegionComplete),
        tap(({ articleId, isRegionView, regionId }) =>
          this.router.navigate(
            isRegionView ? ['marketing', 'regions', regionId, 'news-articles'] : ['marketing', 'news-articles', articleId, 'regions'],
          ),
        ),
      ),
    { dispatch: false },
  );

  onAddNewsArticlePromotion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addNewsArticlePromotion),
      switchMap(({ articleId, promotion }) =>
        this.newsArticlesService.addPromotion(articleId, promotion).pipe(
          map(newsArticle => fromActions.addNewsArticlePromotionComplete({ newsArticle })),
          catchError(err => of(fromActions.addNewsArticlePromotionError({ err }))),
        ),
      ),
    ),
  );

  onUpdateNewsArticlePromotion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateNewsArticlePromotion),
      switchMap(({ articleId, regionId, promotion }) =>
        this.newsArticlesService.updatePromotion(articleId, regionId, promotion).pipe(
          map(newsArticle => fromActions.updateNewsArticlePromotionComplete({ newsArticle })),
          catchError(err => of(fromActions.updateNewsArticlePromotionError({ err }))),
        ),
      ),
    ),
  );

  onGetArticlesWithoutSelectedRegion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getArticlesWithoutSelectedRegion),
      switchMap(() =>
        this.store.pipe(
          select(regionsSelectors.selectSelectedRegionId),
          filter(isNotNullOrUndefined),
          take(1),
          switchMap(regionId =>
            this.newsArticlesService.getAll({ regionId, hasRegions: false, status: 'Active' }).pipe(
              map(articles =>
                fromActions.getArticlesWithoutSelectedRegionComplete({
                  articles: articles.sort((a, b) => b.createdDateTime.getTime() - a.createdDateTime.getTime()),
                }),
              ),
              catchError(err => of(fromActions.getArticlesWithoutSelectedRegionError({ err }))),
            ),
          ),
        ),
      ),
    ),
  );

  onDeleteNewsArticlePromotion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteNewsArticlePromotion),
      switchMap(({ articleId, regionId }) =>
        this.newsArticlesService.deletePromotion(articleId, regionId).pipe(
          map(() => fromActions.deleteNewsArticlePromotionComplete({ articleId, regionId })),
          catchError(err => of(fromActions.deleteNewsArticlePromotionError({ err }))),
        ),
      ),
    ),
  );

  onGoToSelectedNewsArticleRegions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToSelectedNewsArticleRegions),
        tap(({ articleId }) => this.router.navigate(['marketing', 'news-articles', articleId, 'regions'])),
      ),
    { dispatch: false },
  );

  onGoToSelectedNewsArticleRegionActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToSelectedNewsArticleRegionActions),
        tap(({ articleId, regionId }) => this.router.navigate(['marketing', 'news-articles', articleId, 'regions', regionId])),
      ),
    { dispatch: false },
  );

  onCopyExternalLink$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.copyArticleExternalLink),
        tap(({ articleId }) => {
          this.clipboard.copy(`${this.appConfig.config['FRONTEND_PORTAL_URL']}/news-articles/${articleId}`);
          this.notifications.showNotification('Copied successfully!');
        }),
      ),
    { dispatch: false },
  );

  onDuplicateNewsArticle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.duplicateNewsArticle),
      switchMap(({ id, regionId }) =>
        this.newsArticlesService.duplicate(id, regionId ? { regionId } : undefined).pipe(
          map(newsArticle => fromActions.duplicateNewsArticleComplete({ newsArticle, regionId })),
          catchError(err => of(fromActions.duplicateNewsArticleError({ err }))),
        ),
      ),
    ),
  );

  onDuplicateNewsArticleComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.duplicateNewsArticleComplete),
        tap(({ newsArticle, regionId }) =>
          regionId
            ? this.router.navigate(['marketing', 'regions', regionId, 'news-articles', newsArticle.id, 'details'])
            : this.router.navigate(['marketing', 'news-articles', newsArticle.id, 'details']),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly newsArticlesService: NewsArticlesService,
    private readonly router: Router,
    private blobStorageService: BlobStorageService,
    private appConfig: AppConfigService,
    private readonly clipboard: Clipboard,
    private readonly notifications: NotificationsService,
  ) {}
}
