import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { identityActions } from '@appState';
import { Store } from '@ngrx/store';
import { NotificationsService } from 'cui-components';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { partnerNotFoundByMpnId, requiresCspTenantReauthentication } from '../app-pages/csp/util';
import { AppState } from '../app-state/app.store';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private readonly notificationService: NotificationsService,
    private readonly store: Store<AppState>,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (request.headers.has('x-skip-interceptor')) {
          throw error;
        }

        if (error instanceof HttpErrorResponse) {
          if (error.status === 401 && error.error.errorCode !== '401-999') {
            // 401-999 means that refresh token failed and this step needs to be skipped.
            this.store.dispatch(identityActions.userUnauthorized({ err: error }));
            return of();
          }
        }

        if (Object.entries(error).length) {
          if (!partnerNotFoundByMpnId(error) && !requiresCspTenantReauthentication(error)) {
            this.notificationService.showNotification(
              `Status: ${error.status}. Message: ${error?.error?.errorMessage ?? error?.error?.message ?? 'Unknown'}`,
            );
          }
          throw error;
        } else {
          return of();
        }
      }),
    );
  }
}
