import { HttpParams } from '@angular/common/http';

export const mapDefinedParams = (params: Record<string, any>): HttpParams => {
  let httpParams = new HttpParams();
  for (const key in params) {
    if (!!params[key]) httpParams = httpParams.set(key, params[key]);
  }
  return httpParams;
};

export const getFilterFromLocalStorage = (tableId: string): { toggleSearch: string; gridSearch: null | string; id: string } | null => {
  const filterSettings = JSON.parse(localStorage.getItem('TABLE_FILTER_SETTINGS')!);
  return filterSettings?.id === tableId ? filterSettings : null;
};
