import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import * as fromActions from './customers.actions';
import { CustomersService } from './customers.service';

@Injectable()
export class CustomersEffects {
  onGetCustomers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAll),
      switchMap(({ toggle }) =>
        this.customersService.getAll(toggle === 'All').pipe(
          map(customers => fromActions.getAllComplete({ customers })),
          catchError(err => of(fromActions.getAllError({ err }))),
        ),
      ),
    ),
  );

  onSelectCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectCustomer),
      switchMap(({ id }) =>
        this.customersService.getOne(id).pipe(
          map(customer => fromActions.selectCustomerComplete({ customer })),
          catchError(err => of(fromActions.selectCustomerError({ err }))),
        ),
      ),
    ),
  );

  onSetCustomerEntraTenantId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setCustomerEntraTenantId),
      switchMap(({ customerId, req }) =>
        this.customersService.setEntraTenantId(customerId, req).pipe(
          map(customer => fromActions.setCustomerEntraTenantIdComplete({ customer })),
          catchError(err => of(fromActions.setCustomerEntraTenantIdError({ err }))),
        ),
      ),
    ),
  );

  onGetPartners$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAllCustomerPartners),
      switchMap(({ customerId }) =>
        this.customersService.getCustomerPartners(customerId).pipe(
          map(partners => fromActions.getAllCustomerPartnersComplete({ partners })),
          catchError(err => of(fromActions.getAllCustomerPartnersError({ err }))),
        ),
      ),
    ),
  );

  onGoToCustomersActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToCustomerActions),
        tap(({ id }) => this.router.navigate(['customers', id])),
      ),
    { dispatch: false },
  );

  onGoToCustomerList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToCustomerList),
      tap(() => this.router.navigate(['customers'])),
      map(() => fromActions.resetState({ selectedCustomer: null })),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly customersService: CustomersService,
    private readonly router: Router,
  ) {}
}
