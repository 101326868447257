import { Customer, CustomerListItem, CustomerPartnerListItem } from '@models/customers';
import { createReducer, on } from '@ngrx/store';
import { findAndReplace } from '../helpers/helper';
import * as fromActions from './customers.actions';

export interface State {
  isLoading: boolean;
  customers: CustomerListItem[];

  isSelectedCustomerLoading: boolean;
  selectedCustomer: Customer | null;

  customerPartners: CustomerPartnerListItem[];
  isCustomerPartnersLoading: boolean;
}

export const initialState: State = {
  isLoading: false,
  customers: [],

  isSelectedCustomerLoading: false,
  selectedCustomer: null,

  customerPartners: [],
  isCustomerPartnersLoading: false,
};

export const customersReducer = createReducer(
  initialState,

  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),

  on(fromActions.getAll, state => ({ ...state, isLoading: true })),
  on(fromActions.getAllComplete, (state, { customers }) => ({ ...state, customers, isLoading: false })),
  on(fromActions.getAllError, state => ({ ...state, isLoading: false })),

  on(fromActions.selectCustomer, state => ({ ...state, isSelectedCustomerLoading: true, customerRoles: [], customerBusinessData: null })),
  on(fromActions.selectCustomerComplete, (state, { customer }) => ({
    ...state,
    isSelectedCustomerLoading: false,
    selectedCustomer: customer,
  })),
  on(fromActions.selectCustomerError, state => ({ ...state, isSelectedCustomerLoading: false })),

  on(fromActions.setCustomerEntraTenantId, state => ({ ...state, isLoading: true, isSelectedCustomerLoading: true })),
  on(fromActions.setCustomerEntraTenantIdComplete, (state, { customer }) => ({
    ...state,
    isLoading: false,
    isSelectedCustomerLoading: false,
    customers: findAndReplace<CustomerListItem>(state.customers, customer, item => item.id === customer.id),
    selectedCustomer: customer,
  })),
  on(fromActions.setCustomerEntraTenantIdError, state => ({
    ...state,
    isLoading: false,
    isSelectedCustomerLoading: false,
  })),

  on(fromActions.getAllCustomerPartners, state => ({ ...state, isCustomerPartnersLoading: true })),
  on(fromActions.getAllCustomerPartnersComplete, (state, { partners }) => ({
    ...state,
    customerPartners: partners,
    isCustomerPartnersLoading: false,
  })),
  on(fromActions.getAllCustomerPartnersError, state => ({ ...state, isCustomerPartnersLoading: false })),
);
