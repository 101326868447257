import { HttpErrorResponse } from '@angular/common/http';
import { Customer, CustomerListItem, CustomerPartnerListItem, SetCustomerEntraTenantIdRequest } from '@models/customers';
import { createAction, props } from '@ngrx/store';
import { State } from './customers.reducer';

export const ToggleOptions = ['Operative', 'All'] as const;
export type ToggleOptionsName = (typeof ToggleOptions)[number];

const NAMESPACE = '[Customers]';

export const getAll = createAction(`${NAMESPACE} GetAll`, props<{ toggle: ToggleOptionsName }>());
export const getAllComplete = createAction(`${NAMESPACE} GetAllComplete`, props<{ customers: CustomerListItem[] }>());
export const getAllError = createAction(`${NAMESPACE} GetAllError`, props<{ err: HttpErrorResponse }>());

export const selectCustomer = createAction(`${NAMESPACE} SelectCustomer`, props<{ id: string }>());
export const selectCustomerComplete = createAction(`${NAMESPACE} SelectCustomerComplete`, props<{ customer: Customer }>());
export const selectCustomerError = createAction(`${NAMESPACE} SelectCustomerError`, props<{ err: HttpErrorResponse }>());

export const setCustomerEntraTenantId = createAction(
  `${NAMESPACE} SetCustomerEntraTenantId`,
  props<{ customerId: string; req: SetCustomerEntraTenantIdRequest }>(),
);
export const setCustomerEntraTenantIdComplete = createAction(
  `${NAMESPACE} SetCustomerEntraTenantIdComplete`,
  props<{ customer: Customer }>(),
);
export const setCustomerEntraTenantIdError = createAction(
  `${NAMESPACE} SetCustomerEntraTenantIdError`,
  props<{ err: HttpErrorResponse }>(),
);

export const getAllCustomerPartners = createAction(`${NAMESPACE} GetAllCustomerPartners`, props<{ customerId: string }>());
export const getAllCustomerPartnersComplete = createAction(
  `${NAMESPACE} GetAllCustomerPartnersComplete`,
  props<{ partners: CustomerPartnerListItem[] }>(),
);
export const getAllCustomerPartnersError = createAction(`${NAMESPACE} GetAllCustomerPartnersError`, props<{ err: HttpErrorResponse }>());

export const goToCustomerActions = createAction(`${NAMESPACE} GoToCustomerActions`, props<{ id: string }>());
export const goToCustomerList = createAction(`${NAMESPACE} GoToCustomerList`);

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());
