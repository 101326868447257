import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as fromActions from './action-logs.actions';
import { ActionLogsService } from './action-logs.service';

@Injectable()
export class ActionLogsEffects {
  onGetActionLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getActionLogById),
      switchMap(({ actionId }) => {
        return this.actionLogsService.getActionLog(actionId).pipe(
          map(actionLog => fromActions.getActionLogComplete({ actionLog })),
          catchError(() => of(fromActions.getActionLogError())),
        );
      }),
    ),
  );

  onGoToActionLogsList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToActionLogsList),
        switchMap(() => this.router.navigate(['/', 'actions', 'logs'])),
      ),
    { dispatch: false },
  );

  onGoToActionLogDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToActionLogsDetails),
        switchMap(({ actionId }) => this.router.navigate(['/', 'actions', 'logs', actionId])),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private actionLogsService: ActionLogsService,
    private router: Router,
  ) {}
}
